import React, {useContext, useEffect, useState,useCallback,useRef} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';
import {
  SuggestedAnswer,
  MachinResultsContainer,
  GeneratedResultsContainer,
  LineClamp,
  } from '../styles'; // we use the style sheet from results cards
import { Button, Col, Container,Row } from 'react-bootstrap';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';
import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import Hr from '../../hr/index.js';
import { H2,P,Label,A } from '../../theme/typography.js';
import PublisherTag from '../../publisher-tag';
import ResultCard from '../result-card/index.js';
import AnnotationWrapperCard from '../annotation-wrapper-card/index.js';
import {ReactComponent as Sparkles} from '../../../assets/svgs/sparkles-outline.svg';
import AnnotationsCard from '../annotations-card/index.js';



const GeneratedAnswer = (props) => {
    const {
        useTranslatedQuery,
        searchContext,
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [refencesExpanded, setRefencesExpanded] = useState(false);
  const [aiAnswerReady, setAiAnswerReady] = useState(false);
  const [aiQuotesReady, setAiQuotesReady] = useState(false);
  const [streamAnswer, setStreamAnswer] =useState("");
  const [annotations,setAnnotations] =useState(undefined);
  const [listAnnotations,setListAnnotations] =useState(undefined);
  const [elementToScroll, setElementToScroll] =useState(undefined );
  const [keyToScroll, setKeyToScroll] =useState(undefined );
  const AnnotationsRef = useRef(null);

  function toggleAIResults() {
    setContainerIsExpanded(!containerIsExpanded);
  }
  function expandReferences(){
    setRefencesExpanded(true);
  };
  function unexpandReferences(){
    setRefencesExpanded(false);
  };

  // since this function is store for call back
  const expandAnnotationsAndSetScroll = (id,key)=>{
    var target_element_query = "h2 span."+id ; 
    setElementToScroll(target_element_query);
    setKeyToScroll(key);
    if(!refencesExpanded){
      setRefencesExpanded(true);
    }
  };
  
  const addLinksToAnnotation = (annotation_dicts)=> {
    for (const [key, value] of Object.entries(annotation_dicts)) {
      // console.log('key split', key.split('"'));
      let temp_id = key.split('"')[1];
      let temp_elements =  document.querySelectorAll("span."+temp_id);
      temp_elements.forEach(element => {
        let temmp_str = temp_id.slice();
        element.addEventListener("click", ()=>{expandAnnotationsAndSetScroll(temmp_str,key); return false;});
      });
    }
  };

  useEffect(() => {
    if(containerIsExpanded){
      if(annotations && listAnnotations && listAnnotations.length >0  ){
        addLinksToAnnotation(annotations);
      }
    }
  }, [containerIsExpanded,listAnnotations,refencesExpanded]);
  
  useEffect(() => {
    if(props.references && props.references.length >0 && props.references[0]){
      setContainerIsExpanded(false);
    }
  }, [searchContext]);
  

  useEffect(() => {
    let temp_annotations  ; 
    if(annotations){
      temp_annotations =  Object.entries(annotations) ; 

      setListAnnotations(temp_annotations);
      if(!aiQuotesReady){
        setAiQuotesReady(true);
      }
      // addLinksToAnnotation(annotations);
    }
  }, [annotations]);

  
  useEffect(() => {
    let event_source; 
    function answer_event_listener(e_source, event, callback_function, init_result) {
      var data = JSON.parse(event.data);
      if(data && data.result){
        setStreamAnswer(data.result);
      }
      if(data && data.annotations){
        setAnnotations(data.annotations);
      }
      var res = callback_function(init_result, data);
      if (!res) {
          e_source.close();
      } else {
          if (data.type === "final") {
            e_source.close();
          }
      }
    }
  
    function stream_answer_from_query(query, init_for_callback, callback_function) {
      // see answer_event_listener on example of callback_function
      event_source = new EventSource(`https://ask.cairo.medwise.ai/answer_stream?q=${query}`);
      // var event_source = new EventSource(`${window.BASE_URL_ASK}/answer_stream?q=${query}`);
      var init_result = init_for_callback();
      event_source.addEventListener('message', (event) => {
          answer_event_listener(event_source, event, callback_function, init_result)
      });
      event_source.addEventListener("error", (e) => {
        setAiAnswerReady(true);
        event_source.close();
      });
    }
  
  // callback_function
    function process_stream(init_arg, data) {
      if (data.type === "error") {
          return false;
      }
      return true;
    }
  
    function process_text (init_arg,data){
    // if(typeof data === "undefined"){
    //   console.log('158');
    //   return false;
    // }
    return 'bla';
    // setQuebstion(data);
    };

    setContainerIsExpanded(false);
    setAiAnswerReady(false);
    setStreamAnswer('');
    setAiQuotesReady(false);
    setAnnotations(undefined);

    // skip request if using translated query
    if(!useTranslatedQuery){
      stream_answer_from_query( searchContext.q, process_text, process_stream);}
  
    return () => {
      // Clean up the event source when the component is unmounted
      event_source.close();
    };
  }, [searchContext]);


  useEffect(() => {
    if(streamAnswer && streamAnswer!== '' && !aiAnswerReady ){
    setAiAnswerReady(true);}
  }, [streamAnswer]);  
  

  return (
    <GeneratedResultsContainer
        style={{
            paddingBottom: containerIsExpanded && '1px',
            marginBottom:
            containerIsExpanded && !isSmallScreen
                ? '15px'
                : containerIsExpanded && isSmallScreen && '8px',
            background: 'linear-gradient(#eae6f8,#fbfafd)',
        }}
    >
        <SuggestedAnswer
          p1
          regular
          textoffblack
          className={aiAnswerReady ? "tittle_":"blink"}
          onClick={toggleAIResults}
          style={{
            cursor: aiAnswerReady ? 'pointer':'default' ,
            // marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px'
          }}
        >
              <>
              {(aiAnswerReady)? ( 
                <Button
                size="sm"
                className="progress-button"
                style={{
                  cursor: aiAnswerReady ?  'pointer':'default',
                }}
                >
                  {containerIsExpanded  ?  (
                    <FaChevronUp color="#fff"/>
                  ) : (
                    <FaChevronDown color="#fff" />
                  )}
                </Button>):(
                <div
                style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:'4px 6px 0 6px',
                      }}
                >
                <Loader visible={true} color="#8771DF"  height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
                </div>
              )}
              {' '}
              <Container>
               <Row style={{ display:'flex',justifyContent:'space-between'}}>
                <div 
                  style={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 100px)'}}>
                    <LineClamp className= { "truncate"}
                        > Expand this card to see an LLM generated answer, it may be inaccurate</LineClamp>
                </div>
              </Row>
              </Container>
              </>
          
        </SuggestedAnswer>

        {/* AI ASSISTED RESULTS */}
        {containerIsExpanded && aiAnswerReady &&(
          <>
          <Container>
            <Row>
              <div
              className={`card_body_generated_answer `}
              aria-expanded={containerIsExpanded ? true : false}
              style={{display:"flex", flexDirection: "column", alignItems:'center'}}
              >
              <div id='typewriter' style={{ whiteSpace:'break-spaces',width: '100%'}}  className="px-2 py-2"  dangerouslySetInnerHTML={{__html:streamAnswer}}/>
            </div>
            </Row>
            <Hr />
            {aiQuotesReady  && listAnnotations && listAnnotations.length > 0 && (
            <Row className="justify-content-center p-1">
              {!refencesExpanded  ?(
              <Label onClick={expandReferences}>
                 <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown> References <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown>
              </Label>):(
              <Label onClick={unexpandReferences}>
                <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp> References <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp>
              </Label>)
              }
            </Row>)}
            <div ref={AnnotationsRef}>
            {refencesExpanded && aiQuotesReady && listAnnotations &&  listAnnotations.length > 0 &&
            //  listAnnotations.map((annotation,index) => {
            //   return (
            //   <>
            //  <Row>
            //  <AnnotationWrapperCard
            //       annotation_id = {annotation[0]}
            //       annotation = {annotation[1]}
            //       i ={index}></AnnotationWrapperCard>
              
            // </Row>

            //   {/* <Row className='justify-content-center p-3'>
            //       <Hr/>
            //   </Row> */}

            //   </>
            //   )}
            // )
            <AnnotationsCard
             listAnnotations={listAnnotations}
              elementToScroll={elementToScroll}
               setElementToScroll={setElementToScroll}
               keyToScroll ={keyToScroll}
               setKeyToScroll={setKeyToScroll}></AnnotationsCard>
            }
            </div>

          </Container>
          </>)}
    </GeneratedResultsContainer>
  );
};

export default GeneratedAnswer;
