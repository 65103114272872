import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import nhsLoginFocusedIcon from '../../assets/svgs/nhs-login-focused.svg'
import nhsLoginHoveredIcon from '../../assets/svgs/nhs-login-hovered.svg'
import nhsLoginNormalIcon from '../../assets/svgs/nhs-login-normal.svg'
import nhsLoginNormalHeaderIcon from '../../assets/svgs/nhs-login-normal-header.svg'
import nhsLoginFocusedHeaderIcon from '../../assets/svgs/nhs-login-focused-header.svg'
import nhsLoginHoveredHeaderIcon from '../../assets/svgs/nhs-login-hovered-header.svg'
import nhsLoginHoveredHeaderMobileIcon from '../../assets/svgs/nhs-login-hovered-header-mobile.png'
import nhsLoginNormalHeaderMobileIcon from '../../assets/svgs/nhs-login-normal-header-mobile.png'
import nhsLoginFocusedHeaderMobileIcon from '../../assets/svgs/nhs-login-focused-header-mobile.png'

const colors = (props) => {
  if (props.colorprimary) {
    return css`
      color: ${({ theme }) => theme.palette.backgroundWhite};
      background-color: ${({ theme }) => theme.palette.purplePrimary};
      border-color: ${({ theme }) => theme.palette.purplePrimary};

      &:hover {
        background-color: ${({ theme }) => theme.palette.purplePrimaryHover};
      }
    `;
  }

  if (props.colorprimaryreverse) {
    return css`
      background-color: ${({ theme }) => theme.palette.backgroundWhite};
      color: ${({ theme }) => theme.palette.purplePrimary};
      border-color: ${({ theme }) => theme.palette.purplePrimary};

      &:hover {
        background-color: ${({ theme }) => theme.palette.backgroundGrey};
      }
    `;
  }
  if (props.emedblue) {
    return css`
      background-color:  ${({ theme }) => theme.palette.backgroundWhite};
      color: #363636;
      font-weight: 600;
      border-color: #6b7280;

      &:hover {
        background-color:  ${({ theme }) => theme.palette.backgroundGrey};
      }
    `;
  }

  if (props.colorsecondary) {
    return css`
      color: ${({ theme }) => theme.palette.textOffblack};
      background-color: ${({ theme }) => theme.palette.backgroundWhite};
      border-color: ${({ theme }) => theme.palette.backgroundStroke};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.backgroundDropdownHover};
      }
    `;
  }
  if (props.colororange) {
    return css`
      color: ${({ theme }) => theme.palette.textMainbody};
      background-color: ${({ theme }) => theme.palette.orange};
      border-color: ${({ theme }) => theme.palette.backgroundStroke};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.orangeHover};
      }
    `;
  }
  if (props.colororangelanding) {
    return css`
      color: #FFFFFF;
      background-color: ${({ theme }) => theme.palette.orange};
      border-color: ${({ theme }) => theme.palette.backgroundStroke};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.orangeHover};
      }
    `;
  }
  if (props.colorgreen) {
    return css`
      color: ${({ theme }) => theme.palette.textOffblack};
      background-color: ${({ theme }) => theme.palette.greenAddSource};
      border-color: ${({ theme }) => theme.palette.backgroundStroke};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.greenAddSourceHover};
      }
    `;
  }
  if (props.colorlightgreen) {
    return css`
      height: 90%;
      color: #02b1c8;
      background-color: #eff9fb ;
      border-color: #02b1c8;

      &:hover {
        background-color:#d2f3fa;
      }
    `;
  }
  if (props.colorwhite) {
    return css`
      color: ${({ theme }) => theme.palette.textMainbody};
      background-color: ${({ theme }) => theme.palette.backgroundWhite};
      border-color: ${({ theme }) => theme.palette.textOffblack};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.backgroundWhite};
      }
    `;
  }
  if (props.colorgrey) {
    return css`
      color: ${({ theme }) => theme.palette.textMainbody};
      background-color: ${({ theme }) => theme.palette.backgroundGrey};
      border-color: ${({ theme }) => theme.palette.textOffblack};

      &:hover {
        background-color: ${({ theme }) =>
          theme.palette.backgroundGrey};
      }
    `;
  }

  if (props.colorlightpurple) {
    return css`
      color: ${({ theme }) => theme.palette.textOffblack};
      background-color: ${({ theme }) => theme.palette.backgroundPurple};
      border-color: ${({ theme }) => theme.palette.purplePrimary};
      
      &:hover {
        background-color: ${({ theme }) =>
        theme.palette.backgroundPurple};
      }
    `;
  }

  if (props.colorerror) {
    return css`
      color: ${({ theme }) => theme.palette.backgroundWhite};
      background-color: ${({ theme }) => theme.palette.colorError};
      border-color: ${({ theme }) => theme.palette.colorError};

      &:hover {
        opacity: 0.9;
      }
    `;
  }

  if (props.colorcustom) {
    return css`
      color: ${({ theme }) => theme.palette.customBtnTextColor};
      background-color: ${({ theme }) =>
        theme.palette.customBtnBackgroundColor};
      border-color: ${({ theme }) => theme.palette.customBtnBorderColor};

      &:hover {
        color: ${({ theme }) => theme.palette.customBtnTextColorHover};
        background-color: ${({ theme }) =>
          theme.palette.customBtnBackgroundColorHover};
        border-color: ${({ theme }) => theme.palette.customBtnBorderColorHover};
      }
    `;
  }
  // // #FF960F
  // if (props.or) {
  //   return css`
  //   color: ${({ theme }) => theme.palette.backgroundWhite};
  //   background-color: ${({ theme }) => theme.palette.orange};
  //   border-color: ${({ theme }) => theme.palette.orange};

  //   &:hover {
  //     opacity: 0.9;
  //   }
  //   `;
  // }

  if (props.colornhs) {
    return css`
      background-image: url(${nhsLoginNormalIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:hover {
        background-image: url(${nhsLoginHoveredIcon});
      }

      &:focus {
        background-image: url(${nhsLoginFocusedIcon});
        // background-size: 326px 40px;
      }
    `;
  }

  if (props.colornhsheader) {
    return css`
      background-image: url(${nhsLoginNormalHeaderIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 96px 32px;

      &:hover {
        background-image: url(${nhsLoginHoveredHeaderIcon});
      }

      &:focus {
        background-image: url(${nhsLoginFocusedHeaderIcon});
      }
    `;
  }

  if (props.colornhsheadermobile) {
    return css`
      background-image: url(${nhsLoginNormalHeaderMobileIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 370px 48px;

      &:hover {
        background-image: url(${nhsLoginHoveredHeaderMobileIcon});
      }

      &:focus {
        background-image: url(${nhsLoginFocusedHeaderMobileIcon});
      }
    `;
  }
};

const sizes = (props) => {
  if (props.buttonsmall) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.buttonsmall};
      height: ${({ theme }) => theme.spacing.x4}; //32px

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.buttonsmall};
      }
    `;
  }

  if (props.buttonmedium) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.buttonmedium};
      height: ${({ theme }) => theme.spacing.x5}; //40px

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.buttonmedium};
      }
    `;
  }

  if (props.buttonlarge) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.buttonlarge};
      height: ${({ theme }) => theme.spacing.x7}; //56px

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.buttonlarge};
      }
    `;
  }
};

const sharedStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fontfamily.mulish};
  font-weight: ${({ theme }) => theme.fontweight.regular};
  width: ${({ width }) => (width ? width : 'auto')};
  padding: ${({ padding }) => (padding ? padding : 'auto')};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderradius.medium};

  @media (max-width: 767.98px) {
    width: 100%;
    height: 48px;
  }
`;
const retryStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fontfamily.mulish};
  font-weight: ${({ theme }) => theme.fontweight.regular};
  width: ${({ width }) => (width ? width : 'auto')};
  padding: ${({ padding }) => (padding ? padding : 'auto')};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderradius.medium};

  @media (max-width: 767.98px) {
    width: 100%;
    // height: 48px;
  }
`;

const ButtonLink = styled.a`
  ${sharedStyles};
  ${colors};
  ${sizes};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}
`;

const ButtonA = styled.a`
  ${sharedStyles};
  ${colors};
  ${sizes};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}
`;

const ButtonNormal = styled.button`
  ${sharedStyles};
  ${colors};
  ${sizes};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}
`;

const ButtonRetry = styled.button`
  ${retryStyles};
  ${colors};
  ${sizes};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}
`;

const ButtonAction = styled.button`
  ${sharedStyles};
  ${colors};
  ${sizes};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}
`;

export { ButtonLink, ButtonNormal, ButtonRetry, ButtonAction, ButtonA };
