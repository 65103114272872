import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  createRef,
  useMemo,
} from 'react';
import axios from 'axios';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';
import { AppContext } from '../../context';
import { isColor, stripTags } from '../../functions/utils';
import AcResult from './AcResult';
import Styles from './styles';
import { FaSearch, FaTimes } from 'react-icons/fa';
import {suggest_on, sources} from '../../functions/frontend_variables';
import { getAllSources,getDefaultFiltersFromContext } from '../../functions/manageSessions';
import AcUserResult from './AcUserResult';
import AcLlmResult from './AcLlmResult';
import { isMobile } from 'react-device-detect'; // we need to detect phones not small screens
import { removeDuplicatesFromSecondArray, countWords } from '../../functions/utils';


const SearchBar = ({ isAtHeader, btnColor }) => {
  const searchBox = createRef();
  const [acResults, setAcResults] = useState([]);
  const [acUserResults, setUserAcResults] = useState([]);
  const [acLlmResults, setLlmAcResults] = useState([]);
  const [value, setValue] = useState(''); // value has 2 function: represent input value of the user, and trigger suggestions
  const [shadowValue, setShadowValue] = useState('');
  const [cursor, setCursor] = useState(0);
  const [triggerSuggest, setTriggerSuggest] = useState(false);
  const [searchBtnColor, setSearchBtnColor] = useState(undefined);
  const [resUserSuggest, setResUserSuggest] = useState(undefined); // only need for logs 
  const isSmallScreen = useIsSmallScreen();

  const {
    searchContext,
    setSearchContext,
    userContext,
    mobileContext,
    setMobileContext,
    setQueries,
    setIsResultsExpanded,
    setResultsFirstExpanded,
    setConfirmCouldNotFind,
    setSuspectCouldNotFind,
    setLoadMore,
    anonQuery,
    setAnonQuery,
    setClearLocalWebResults,
    setShowAssistedAI,
    setLocalWebResults,
    userContextFilters,
  } = useContext(AppContext);

  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const allSources = useMemo( ()=> 
    {
      let allSourcesObj= getAllSources();
      let allSources = '';

      allSourcesObj.forEach(element => {
        if (element['encrypted_url']){
          allSources = allSources + ',' +element['encrypted_url'] ;
        }
      });
      allSources = allSources.substring(1);
      return allSources;
    } ,[userContextFilters]);
 
  // triggers the 3 suggestions
  useEffect(() => {
    if(suggest_on && ( value !=="" || triggerSuggest ) ){
      if ( typeof value === "string") {
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          url: window.BASE_URL_ASK+"suggest",
          withCredentials: true,
          signal: controller.signal,
          cancelToken: source.token,
          data: JSON.stringify({
            q: value,
            sources: allSources,
          }),
          })
          .then((res) => {
            if(typeof res.data['suggestions'] !== 'undefined' && res.data['suggestions'] ){
              let noDublicatesSuggestions = [...new Set(res.data['suggestions'])];
              setAcResults(noDublicatesSuggestions);
            }
          })
          .catch((error) => {
              console.log("suggestion",error);
          }); 
        axios({
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            url: window.BASE_URL_USER+"user-suggestions",
            withCredentials: true,
            signal: controller.signal,
            cancelToken: source.token,
            data: JSON.stringify({
              q: value,
              sources: allSources,
            })
          })
          .then((res) => {
            let allSuggestions = []; 
            // we dont want empty suggestions but we do want an empty list if that is the return
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'suggestions') ){
              allSuggestions = allSuggestions.concat(res.data['suggestions']);
            }
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'org-suggestions')){
              allSuggestions = allSuggestions.concat(res.data['org-suggestions']);// set do no have repeats but its an object 
            }
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'llm-suggestions')){
              allSuggestions = allSuggestions.concat(res.data['llm-suggestions']);// set do no have repeats but its an object 
            }
            allSuggestions = [...new Set(allSuggestions)];// set do no have repeats but its an object 
            setUserAcResults(allSuggestions);
              // if(typeof res.data['suggestions'(] !== 'undefined' && res.data['suggestions']  ){
              // if(typeof res.data === 'object' &&(Object.hasOwn(res.data,'suggestions') 
              //     || Object.hasOwn(res.data,'org-suggestions') 
              //     || Object.hasOwn(res.data,'llm-suggestions') )){
              //   setResUserSuggest(res.data); // this value is just pass down to a child component for logs 
              //   if (typeof res.data['org-suggestions'] !== 'undefined' || Object.hasOwn(res.data,'llm-suggestions')) {
              //     allSuggestions = [...new Set(res.data['suggestions'].concat(res.data['org-suggestions']))];// set do no have repeats but its an object 
              //     setUserAcResults(allSuggestions); // return 
              //   } 
              //   else {
              //     setUserAcResults(res.data['suggestions']);
              //   }
              // }
          })
          .catch((error) => {
              console.log("suggestion",error);
          });
          axios({
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            url: window.BASE_URL_USER+"llm-suggestions",
            withCredentials: true,
            signal: controller.signal,
            cancelToken: source.token,
            data: JSON.stringify({
              q: value,
              sources: allSources,
            })
          })
          .then((res) => {
            let allSuggestions = []; 
            // we dont want empty suggestions but we do want an empty list if that is the return
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'suggestions') ){
              allSuggestions = allSuggestions.concat(res.data['suggestions']);
            }
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'org-suggestions')){
              allSuggestions = allSuggestions.concat(res.data['org-suggestions']);// set do no have repeats but its an object 
            }
            if(typeof res.data === 'object' && Object.hasOwn(res.data,'llm-suggestions')){
              allSuggestions = allSuggestions.concat(res.data['llm-suggestions']);// set do no have repeats but its an object 
            }
            allSuggestions = [...new Set(allSuggestions)];// set do no have repeats but its an object 
            setLlmAcResults(allSuggestions);
          })
          .catch((error) => {
              console.log("llm suggestions",error);
          });       
      }
    }
    return () => {
      // cancel pending request
      // also we need to cancel the request if ask is sent
      source.cancel('user-suggestions clean up');
    };
  }, [value,triggerSuggest]);

  const handleInputChange = (e) => {
    if(anonQuery && anonQuery !== '' ){
      setAnonQuery('');// we do not want to unecessary change the state 
    }
    // Set cursor to zero as user types, so that the cursor will not have an index to grab a value from
    setCursor(0); 
    // TODO also reset setHoveredAC ??  
    setAcResults(undefined);
    setUserAcResults(undefined);
    setLlmAcResults(undefined);
    setValue(e.target.value);// trigger suggestions 
    setShadowValue(e.target.value); //change shaddow value
  };

  const handleInputClick = () => {
    if (shadowValue) {
      setValue(shadowValue);
    }
    else{
      setValue('');
      setTriggerSuggest(true);
    }
  };

  // This where the submission is. 
  // Change of "q" parameter in setSearchContext triggers the API call in context if queryFromACClick or shadowValue exists.
  // queryFromACClick is passed to AcResult.js  && AcUserResult-> We no longer update the shadowValue on each AcResult hover. Therefore, 'clicked value' needs to be passed to this function to trigger the search.
  const querySubmit = useCallback(
    (e, queryFromACClick) => {
      var default_filters_to_use =  searchContext.facets;
      if(!isAtHeader){
        // default_filters_to_use =  getDefaultFilters();
        default_filters_to_use =  getDefaultFiltersFromContext(userContextFilters);
        
      }
      e.preventDefault();
      let query;
      if (queryFromACClick) {
        query = stripTags(queryFromACClick);
      } else if (shadowValue) {
        query = shadowValue;
      }
      if(anonQuery!==''  && typeof queryFromACClick === 'undefined' ){
        query = anonQuery;
        setAnonQuery('');// anon query should only be user once (after login/ssignup)
      }
      setShadowValue(query);
      setTriggerSuggest(false); // we reset trigger suggest to false
      setValue(query);
      if (query && query.length > 0) {
        query = query.trim();
        if (countWords(query) > 4) {
          setIsResultsExpanded(true);
          setResultsFirstExpanded(undefined);
        } else {
          setIsResultsExpanded(false);
          setResultsFirstExpanded(false);
        };
        setSuspectCouldNotFind(false);
        setConfirmCouldNotFind(false);
        setLoadMore(undefined);
        setClearLocalWebResults(false);
        setShowAssistedAI(true);
        setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
      }

      // for anonymous user store query for later use
      // and send to login
      if(userContext && typeof userContext !== 'undefined'  &&  userContext['is_anonymous']){
        setAnonQuery(query);// this is the only way to store the query , other part of the code can simply check if === '' to know if user justy login/signup 
        window.open('/signup',"_self");
      }
      else{ 
        // we need to cancel suggestions requets beofre ask
        source.cancel('user-suggestions clean up');
        // This state triggers the API call
        setSearchContext({
          ...searchContext,
          demo_q_clicked: false,
          "from-trending":false,
          q: query,
          facets: default_filters_to_use ,
          covid_only: false,
          forceSearch: true,
        });
      } 
      setQueries(queries => [query]);

      // we need to cancel suggestions requets beofre ask
      source.cancel('user-suggestions clean up');
      // This state resets all mobile filters and other UI triggers
      setMobileContext({
        ...mobileContext,
        clear: false,
        label: (default_filters_to_use.length ===  sources.length)?  'Off':'On',
        toggle: false,
        facetEntries: default_filters_to_use,
        covidSwitch: false,
      });
      closeAutoCompleteWrapperSubmit();
    },
    // eslint-disable-next-line
    [shadowValue, searchContext, userContext, userContextFilters]
  );

  // Close the autocomplete wrapper. Same as useOutsideClickDetector and escape button press
  const closeAutoCompleteWrapper = () => {
    setShadowValue(value);
    setValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    setLlmAcResults(undefined);
    setCursor(0);
    //TO DO , should setHoveredAC also be reset ?? 
  };

  // Close the autocomplete wrapper for submit
  const closeAutoCompleteWrapperSubmit = () => {
    // setShadowValue(shadowValue);
    setValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    setLlmAcResults(undefined);
    setCursor(0);
    // in mobile input is no deseleted by default
    // and we want mobile  keyboard to go 
    if (isSmallScreen ){
      if(document.getElementById("searchBar")){
        document.getElementById("searchBar").blur(); }
    }
    //TO DO , should setHoveredAC also be reset ?? 
  };

  const clearSearch = () => {
    setTriggerSuggest(false);// we want to avoid trigger suggest when clean button is click
    setValue('');
    setShadowValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    setLlmAcResults(undefined);
    //TO DO , should setHoveredAC also be reset ?? 

  };

  const useOutsideClickDetector = (ref) => {
    useEffect(() => {
      const handleClickOutside = (e) => {
        // Below !e.target.value condition prevents autocomplete to re-run when input field is repetitevely clicked
        if (ref.current && !ref.current.contains(e.target) && !e.target.value) {
          setAcResults(undefined);
          setUserAcResults(undefined);
          setLlmAcResults(undefined);
          setCursor(0);
          //TO DO , should setHoveredAC also be reset ?? 
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('scroll', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('scroll', handleClickOutside);
      };
    }, [ref]);
  };


  const cleanValueSuggestion = (i, combined_array) => {
    let cleanValue ;
      if( combined_array && i<combined_array.length && combined_array[i] && typeof combined_array[i]!== "string"  
          && typeof combined_array[i]["suggestion"] === "string" ){
        // console.log(combined_array[i]);
        cleanValue = stripTags(combined_array[i]["suggestion"]);
      }
      else{
        if(combined_array && Object.hasOwn(combined_array,i)){
        cleanValue = stripTags(combined_array[i]);
        }
      }
    return cleanValue;
  };


  const getCombinedListWithObjects = (list0, list1) =>{
    let combinedList ;
    let tempList0=[];
    let tempList1=[];
    if(list0 && list0.length >0 ){
      tempList0  = list0.filter((x) => typeof x['suggestion']!== 'undefined');
      tempList0 = tempList0.map(x=>x["suggestion"]);
    }
    if(list1 && list1.length >0 ){
      tempList1  = list1.filter((x) => typeof x['suggestion']!== 'undefined');
      tempList1 = tempList1.map(x=>x["suggestion"]);
    }
    if(tempList0 && tempList1 && tempList0.length>0 && tempList1.length>0 ){
      combinedList = tempList0.concat(removeDuplicatesFromSecondArray(tempList0,tempList1)) ;
    }
    else{
      combinedList = tempList0.concat(tempList1)
    }
    return combinedList; 
  };

  const getCombinedList = (listObject, listOfStrings) =>{
    let combinedList ;
    let tempListObject=[];
    if(listObject && listObject.length >0 ){
      tempListObject  = listObject.filter((x) => typeof x['suggestion']!== 'undefined');
      tempListObject = tempListObject.map(x=>x["suggestion"]);
    }
    if(tempListObject && listOfStrings && tempListObject.length>0 && listOfStrings.length>0 ){
      combinedList = tempListObject.concat(removeDuplicatesFromSecondArray(tempListObject,listOfStrings)) ;
    }
    else{
      // on of the list have to be empty
      if(typeof listOfStrings !== 'undefined' ){
        combinedList = tempListObject.concat(listOfStrings)
      }
      else{
        // if listofString undefined we replace with empty list
        combinedList = tempListObject.concat([])
      }
    }
    return combinedList; 
  };

  const handleKeyDown = (e)=>{
    let combined_array = [];
    if ((acUserResults && acUserResults.length > 0 )||(acResults && acResults.length > 0)){
      // combined_array =acUserResults;
      // get the second array without duplicates
      // let tempUserResults =  acUserResults.map(x=>x["suggestion"]  );
      let tempUserResults = getCombinedList(acUserResults,acResults);
      // whne both are bigger than zero we compbine      
      if (tempUserResults && tempUserResults.length > 0 && acLlmResults && acLlmResults.length>0){
        let tempLLmResults  = acLlmResults.filter((x) => typeof x['suggestion']!== 'undefined');
        tempLLmResults = tempLLmResults.map(x=>x["suggestion"]);
        let tempList = removeDuplicatesFromSecondArray(tempUserResults,tempLLmResults) ;
        // combined_array =acUserResults.concat(tempList);
        combined_array =tempUserResults.concat(tempList);
      }
      // if one is empty then we use the other as combined 
      else {
        if(tempUserResults && tempUserResults.length>0 && (acLlmResults && acLlmResults.length <= 0  || typeof acLlmResults === 'undefined')){
          combined_array = tempUserResults;
        }
        if((tempUserResults && tempUserResults.length <= 0 || typeof tempUserResults === 'undefined')&& acLlmResults && acLlmResults.length > 0 ){
          combined_array = acLlmResults;
        }
      }
    }
    else {
      if( acLlmResults && acLlmResults.length > 0  ){
        let tempLLmResults  = acLlmResults.filter((x) => typeof x['suggestion']!== 'undefined');
        tempLLmResults = tempLLmResults.map(x=>x["suggestion"]);
        combined_array = tempLLmResults;}
    }
    let tempIndex;
    let updateDisplay= false;
    if(e.key === "ArrowDown"){
      tempIndex = (cursor +1) %  (combined_array.length+1);
      updateDisplay = true;
      setCursor(prev =>  tempIndex );
    }
    if(e.key === "ArrowUp"){
      e.preventDefault();
      updateDisplay= true;
      tempIndex= (cursor -1) %  (combined_array.length+1);
      if(tempIndex <0){
        tempIndex = tempIndex + (combined_array.length+1);
      }
      setCursor(prev => tempIndex) ;
    }
    if(e.key === "Escape"){
      setCursor(0);
      updateDisplay= true;
      closeAutoCompleteWrapper();
    } 
    if(tempIndex === 0 &&  updateDisplay === true ){
      setShadowValue(value);
    }
    else{
      if(updateDisplay === true ){
        let tempCleanValue = cleanValueSuggestion(tempIndex-1, combined_array);
        setShadowValue(tempCleanValue) ;
      }
    }
  };

  const acRef = useRef(null);
  useOutsideClickDetector(acRef);

  useEffect(() => {
    if (isColor(btnColor) ) {
      setSearchBtnColor(btnColor);
    }
    else{
      setSearchBtnColor(undefined);
    }
  }, [btnColor]);

  useEffect(() => {
    setShadowValue(searchContext.q);
  }, [searchContext.q]);


  return (
    <Styles isAtHeader={isAtHeader} isMobile= {isSmallScreen} style={{marginTop: '12px'}}>
      <form onSubmit={querySubmit}>
        <div className="search_container">
          <div className="search_inputGroupWithButton">
            <div className="search_inputGroup">
              <div className="search_inputGroup--magnify" aria-label="Search">
                <FaSearch title="search icon" />
              </div>
              <input
                autoComplete="off" // remove browser autocomplete
                type="text"
                className={`search_inputGroup--input ${acResults ? 'active' : '' }`} // should acUserResult alos be here ???
                value={anonQuery !== '' ?  anonQuery:shadowValue}
                onInput={(e) => handleInputChange(e)}
                onClick={()=>handleInputClick()}
                placeholder="Search now..."
                ref={searchBox}
                autoFocus={!isSmallScreen }
                onKeyDown={(e) => handleKeyDown(e)}
                id='searchBar'
              />
              {(shadowValue ? shadowValue : value) && (
                <>
                <div
                  className="search_inputGroup--clear"
                >
                {/* <div
                 tabIndex="3"
                 aria-label="Clear"
                 onClick={clearSearch}> */}
                  <FaTimes onClick={clearSearch} title="clear icon"/>
                  {/* </div> */}
                </div>
              </>
              )}
            </div>
            <button
              className={`search_inputGroup--button ${acResults ? 'active' : ''}`} // should acUserResult alos be here ???
              type="submit"
              style={{'backgroundColor':searchBtnColor}}
            >
              <span className="search_inputGroup--button--icon">
                <FaSearch title="search icon" />
              </span>
              {userContext && typeof userContext !== 'undefined'  &&  userContext['is_anonymous']  ?(
                <span className="search_inputGroup--button--text" >Sign up to Search</span>
              ):
                <span className="search_inputGroup--button--text" >Search</span>
              }
            </button>    
          </div>
          { suggest_on && (
            <div className="ac" ref={acRef} >
              {/* maxheight brakes any keyboard up and down functionality so only use on mobile not on small screens
              we are assuming mobiles don t have keyboards 
              and it also show just the suggestions that fit */}
              <ul className="ac_list" style={{maxHeight:isMobile? '25vh':''}}> 
                {acUserResults&& acUserResults.length >0  &&(
                <AcUserResult
                  list={acUserResults}
                  cursor={cursor} 
                  setHoveredAC={setCursor}
                  querySubmit={querySubmit}
                  q={value}
                  result={resUserSuggest}
                />)}
                {acResults&& acResults.length >0  && (
                  <AcResult
                    acList={acResults}
                    userList={acUserResults}
                    cursor={cursor}
                    setHoveredAC={setCursor}
                    querySubmit={querySubmit}
                  />)}
                  {acLlmResults && acLlmResults.length >0  && (
                  <AcLlmResult
                    llmList={acLlmResults}
                    userList={acUserResults}
                    acList={acResults}
                    cursor={cursor}
                    setHoveredAC={setCursor}
                    querySubmit={querySubmit}
                  />)}  
              </ul>
            </div>
          )}
        </div>
      </form>
    </Styles>
  );
};

export default SearchBar;
