import React from 'react';
import { useEffect,  useState} from 'react';
import { FaSearch } from 'react-icons/fa';
// import {FaStethoscope}
import { stripTags, stripTagsArray,removeDuplicatesFromSecondArray } from '../../functions/utils';
import { P } from '../theme/typography';

const AcLlmResult = ({ llmList, userList, acList, cursor, querySubmit, setHoveredAC }) => {
  // var index_add =0; 
  // let new_list = list;
  const [secondToDisplayList, setsecondToDisplayList] = useState(llmList);
  const [indexAdd, setIndexAdd] = useState(0);


  // returns a list of string without dublicates
  const getCombinedList= (listOfStrings, listObjects) =>{
    let combinedList ;
    let tempList=[];
    if(listObjects && listObjects.length >0 ){
      tempList  = listObjects.filter((x) => typeof x['suggestion']!== 'undefined');
      tempList = tempList.map(x=>x["suggestion"]);
    }
    if(listOfStrings && tempList && listOfStrings.length>0 && tempList.length>0 ){
      combinedList = listOfStrings.concat(removeDuplicatesFromSecondArray(listOfStrings,tempList)) ;
    }
    else{
      if(listOfStrings && listOfStrings.length>0 ){
        combinedList = listOfStrings.concat(tempList)
      }
      else{
        combinedList = tempList;
      }
    }
    return combinedList; 
  };


  useEffect(()=>{
    let processLlmList =  llmList.map(x=>x["suggestion"] );
    let combinedList =[] 
    if( (acList && acList.length >0) || (userList && userList.length>0) ){
      combinedList =  getCombinedList(acList,userList)
    }
    if(combinedList && combinedList.length> 0 && processLlmList && processLlmList.length>0){
      let llmListNoDublicates = removeDuplicatesFromSecondArray(combinedList,processLlmList) ;
      setIndexAdd(combinedList.length);
      setsecondToDisplayList(llmListNoDublicates);
    }
    else{
      setIndexAdd(0);
      setsecondToDisplayList(processLlmList);
    }
  },[llmList, userList,acList]);

  return (
      // If AC list item needs to be filtered, it can be chained here.
      secondToDisplayList.map((value,index) => {
        return (
          <li
            key={index+indexAdd}
            onClick={(e) => querySubmit(e, value)}
            onMouseEnter={() => setHoveredAC(indexAdd+index+1)}
            onMouseLeave={() => setHoveredAC(0)}
            className={`ac_list--item ${(index+indexAdd + 1) === cursor ? 'active' +' ' : ''}`}
          >
            <div className="ac_list--item--magnify" aria-label="Search">
            <FaSearch title="search icon" />
            </div>
            <P
              p1
              regular
              textoffblack
              dangerouslySetInnerHTML={{ __html: value }}
            ></P>
          </li>
        );
      })
  );
};

export default AcLlmResult;
