import React, { useMemo, useContext, useState, useEffect }from "react";
import { AppContext } from '../../context';

const PDFPreview = ({ data, isFullSize, setShowPDFModalFlag, index }) => {
  const { 
    userContext,
  } = useContext(
    AppContext
  );
  const [mouseOverIframe, setMouseOverIframe] = useState(false);

  const previewUrl = useMemo(
    () => {
      if (data) {
        return data.replace("__PUBLIC_TOKEN__", userContext.search_params);
      }
    }, [data]
  );

  useEffect(() => {
    // Focus the page
    window.focus();
    // Add listener to check when page is not focussed
    // (i.e. iframe is clicked into)
    window.addEventListener("blur",onWindowBlur);
    return () => {
      window.removeEventListener("blur",onWindowBlur);
    }
  }, [mouseOverIframe]);

  const onWindowBlur = () => {
    if (mouseOverIframe) {
      setShowPDFModalFlag(index);
    }
  };

  const handleOnMouseOut = () => {
    window.focus(); // Make sure to set focus back to page
    setMouseOverIframe(false);
  };

  const handleOnMouseOver = () => {
    setMouseOverIframe(true);
  };

  return (
    <iframe className="pdf-preview" onMouseOver={(e)=>{handleOnMouseOver()}} onMouseOut={(e)=>{handleOnMouseOut()}} 
      style={{border: 'none', width: isFullSize && '100%', height: isFullSize && '100%',overflow: 'scroll'}} type="application/pdf"
      src={previewUrl} width={!isFullSize && "100"} height={!isFullSize &&"141"}></iframe>
  );
};

export default PDFPreview;