import React from 'react';
import { useEffect,  useState} from 'react';
import { FaSearch } from 'react-icons/fa';
import { stripTags, stripTagsArray,removeDuplicatesFromSecondArray } from '../../functions/utils';
import { P } from '../theme/typography';

const AcResult = ({ acList, userList, cursor, querySubmit, setHoveredAC }) => {
  // var index_add =0; 
  // let new_list = list;
  const [secondToDisplayList, setsecondToDisplayList] = useState(acList);
  const [indexOffset, setIndexOffset] = useState(0);

  const getCombinedListWithObjects = (list0, list1) =>{
    let combinedList ;
    let tempList0=[];
    let tempList1=[];
    if(list0 && list0.length >0 ){
      tempList0  = list0.filter((x) => typeof x['suggestion']!== 'undefined');
      tempList0 = tempList0.map(x=>x["suggestion"]);
    }
    if(list1 && list1.length >0 ){
      tempList1  = list1.filter((x) => typeof x['suggestion']!== 'undefined');
      tempList1 = tempList1.map(x=>x["suggestion"]);
    }
    if(tempList0 && tempList1 && tempList0.length>0 && tempList1.length>0 ){
      combinedList = tempList0.concat(removeDuplicatesFromSecondArray(tempList0,tempList1)) ;
    }
    else{
      combinedList = tempList0.concat(tempList1) 
    }
    return combinedList; 
  };

  useEffect(()=>{
    if((userList && userList.length> 0 )  ){
      let tempUserlist  = userList.filter((x) => typeof x['suggestion']!== 'undefined');
       tempUserlist =  tempUserlist.map(x=>x["suggestion"] );
      if (tempUserlist && tempUserlist.length > 0 ){
        let tempList = removeDuplicatesFromSecondArray(tempUserlist,acList) ;
        setIndexOffset(tempUserlist.length);
        setsecondToDisplayList(tempList);
      }
    }
    else{
      setIndexOffset(0);
      setsecondToDisplayList(acList);
    }
  },[acList, userList]);

  return (
      // If AC acList item needs to be filtered, it can be chained here.
      secondToDisplayList.map((value,index) => {
        return (
          <li
            key={index+indexOffset}
            onClick={(e) => querySubmit(e, value)}
            onMouseEnter={() => setHoveredAC(indexOffset+index+1)}
            onMouseLeave={() => setHoveredAC(0)}
            className={`ac_list--item ${(index+indexOffset + 1) === cursor ? 'active' +' ' : ''}`}
          >
            <div className="ac_list--item--magnify" aria-label="Search">
              <FaSearch title="search icon" />
            </div>
            <P
              p1
              regular
              textoffblack
              dangerouslySetInnerHTML={{ __html: value }}
            ></P>
          </li>
        );
      })
  );
};

export default AcResult;
